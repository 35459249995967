<template>
  <div id="open-ai-wrap" class="open-ai-wrap" @dragend="enddrag">
    <div
      id="open-ai-button"
      class="open-ai-button"
      draggable="true"
      @click="showDialogFn"
    >
      <!-- <img src="../../assets/img/AIdialog.jpg" alt="" /> -->
      <i class="iconfont icon-a-zhishikuai1" style="font-size: 26px"></i>
    </div>

    <transition name="el-zoom-in-bottom">
      <div v-show="showDialog" id="open-ai-dialog" class="open-ai-dialog">
        <div class="close-open-ai-dialog" @click="showDialog = false">
          <i class="el-icon-close"></i>
        </div>
        <el-tabs v-model="activeName">
          <el-tab-pane :label="$t('AI.tip39')" name="four">
            <div class="tab-inner">
              <div id="tab4-inner-text" class="tab-inner-text">
                <div v-for="(item, index) in ragList" :key="index">
                  <transition name="el-fade-in-linear">
                    <div v-if="item.type === '1'" class="chat-box human-chart">
                      <span v-html="item.content"></span>
                    </div>
                    <div
                      v-if="item.type === '2' && !item.isRobot"
                      class="chat-box robot-chart"
                    >
                      <span v-html="item.content"></span>
                    </div>
                    <div
                      v-if="item.type === '2' && item.isRobot"
                      class="chat-box robot-chart"
                      @contextmenu.prevent="openMenu($event, item)"
                    >
                      <span class="chartgtp-box" v-html="item.content"></span>
                      <label
                        v-if="robotTyping && index == ragList.length - 1"
                        class="dot-elastic"
                      ></label>
                      <div
                        v-show="item.contextMenuVisible"
                        class="context-menu"
                        :style="{
                          left: contextMenuLeft + 'px',
                          top: contextMenuTop + 'px',
                        }"
                      >
                        <div>
                          <div @click.stop="copyText">{{ $t("AI.tip2") }}</div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="input-wrap input-wrap-deep">
                <el-input
                  v-model="ragInputValue"
                  :disabled="ragInputDisabled"
                  size="medium"
                  :placeholder="$t('AI.tip50')"
                  @keyup.enter.native="tab4SendMessage"
                >
                  <template slot="suffix">
                    <i
                      v-show="!ragInputDisabled"
                      class="iconfont icon-fasong"
                      style="color: rgba(42, 130, 228, 1); font-size: 20px"
                      @click="tab4SendMessage"
                    >
                    </i>
                  </template>
                </el-input>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('AI.tip1')" name="first">
            <div class="tab-inner">
              <div id="tab1-inner-text" class="tab-inner-text">
                <innerOptions
                  :robot-typing="robotTyping"
                  :option-list="creatOptionList"
                  @optionSelect="optionSelect"
                ></innerOptions>
                <div v-for="(item, index) in creatChatList" :key="index">
                  <transition name="el-fade-in-linear">
                    <div v-if="item.type === '1'" class="chat-box human-chart">
                      <span v-html="item.content"></span>
                    </div>
                    <div
                      v-if="item.type === '2' && !item.isRobot"
                      class="chat-box robot-chart"
                    >
                      <span v-html="item.content"></span>
                    </div>
                    <div
                      v-if="item.type === '2' && item.isRobot"
                      class="chat-box robot-chart"
                      @contextmenu.prevent="openMenu($event, item)"
                    >
                      <span class="chartgtp-box" v-html="item.content"></span>
                      <label
                        v-if="robotTyping && index == creatChatList.length - 1"
                        class="dot-elastic"
                      ></label>
                      <div
                        v-show="item.contextMenuVisible"
                        class="context-menu"
                        :style="{
                          left: contextMenuLeft + 'px',
                          top: contextMenuTop + 'px',
                        }"
                      >
                        <div>
                          <div @click.stop="copyText">{{ $t("AI.tip2") }}</div>
                          <div
                            v-if="
                              item.opt !== 'GIVEN_CODE_CREATE' &&
                              item.opt !== 'GENERATE_CODE_OPTIMIZE'
                            "
                            @click.stop="creatFile(item)"
                          >
                            {{ $t("AI.tip3") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <innerOptions
                      v-if="item.type == '3'"
                      :list-index="index"
                      :option-list="optimizeOptionList"
                      @optionSelect="optionSelect"
                    ></innerOptions>
                  </transition>
                </div>
              </div>
              <div class="input-wrap input-wrap-deep">
                <el-input
                  v-model="creatInputValue"
                  :disabled="inputDisabled"
                  size="medium"
                  :placeholder="$t('AI.tip4')"
                  @keyup.enter.native="tab1SendMessage"
                >
                  <template slot="suffix">
                    <i
                      v-show="!inputDisabled"
                      class="iconfont icon-fasong"
                      style="color: rgba(42, 130, 228, 1); font-size: 20px"
                      @click="tab1SendMessage"
                    >
                    </i>
                  </template>
                </el-input>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('AI.tip5')" name="second">
            <div class="tab-inner">
              <div id="tab2-inner-text" class="tab-inner-text">
                <innerOptions
                  :robot-typing="robotTyping"
                  :option-list="perfectOptionList"
                  @optionSelect="optionSelect"
                ></innerOptions>
                <div v-for="(item, index) in perfectChatList" :key="index">
                  <transition name="el-fade-in-linear">
                    <div v-if="item.type === '1'" class="chat-box human-chart">
                      <span v-html="item.content"></span>
                    </div>
                    <div
                      v-if="item.type === '2' && !item.isRobot"
                      class="chat-box robot-chart"
                    >
                      <span v-html="item.content"></span>
                    </div>
                    <div
                      v-if="item.type === '2' && item.isRobot"
                      class="chat-box robot-chart"
                      @contextmenu.prevent="openMenu($event, item)"
                    >
                      <span class="chartgtp-box" v-html="item.content"></span>
                      <label
                        v-if="
                          robotTyping && index == perfectChatList.length - 1
                        "
                        class="dot-elastic"
                      ></label>
                      <div
                        v-show="item.contextMenuVisible"
                        class="context-menu"
                        :style="{
                          left: contextMenuLeft + 'px',
                          top: contextMenuTop + 'px',
                        }"
                      >
                        <div>
                          <div @click.stop="copyText">{{ $t("AI.tip2") }}</div>
                          <div
                            v-if="
                              item.opt !== 'GIVEN_CODE_OPTIMIZE' &&
                              item.opt !== 'GENERATE_CODE_OPTIMIZE'
                            "
                            @click.stop="creatFile(item)"
                          >
                            {{ $t("AI.tip3") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <innerOptions
                      v-if="item.type == '3'"
                      :list-index="index"
                      :option-list="optimizeOptionList"
                      @optionSelect="optionSelect"
                    ></innerOptions>
                  </transition>
                </div>
              </div>
              <div class="input-wrap input-wrap-deep">
                <el-input
                  v-model="perfectInputValue"
                  :disabled="inputDisabled"
                  size="medium"
                  :placeholder="$t('AI.tip4')"
                  @keyup.enter.native="tab2SendMessage"
                >
                  <template slot="suffix">
                    <i
                      v-show="!inputDisabled"
                      class="iconfont icon-fasong"
                      style="color: rgba(42, 130, 228, 1); font-size: 20px"
                      @click="tab2SendMessage"
                    >
                    </i>
                  </template>
                </el-input>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="SafetyGPT" name="third">
            <div class="tab-inner">
              <div id="tab3-inner-text" class="tab-inner-text">
                <div v-for="(item, index) in gptChatList" :key="index">
                  <transition name="el-fade-in-linear">
                    <div v-if="item.type === '1'" class="chat-box human-chart">
                      <span v-html="item.content"></span>
                    </div>
                    <div
                      v-if="item.type === '2' && !item.isRobot"
                      class="chat-box robot-chart"
                    >
                      <span v-html="item.content"></span>
                    </div>
                    <div
                      v-if="item.type === '2' && item.isRobot"
                      class="chat-box robot-chart"
                      @contextmenu.prevent="openMenu($event, item)"
                    >
                      <span class="chartgtp-box" v-html="item.content"></span>
                      <label
                        v-if="robotTyping && index == gptChatList.length - 1"
                        class="dot-elastic"
                      ></label>
                      <div
                        v-show="item.contextMenuVisible"
                        class="context-menu"
                        :style="{
                          left: contextMenuLeft + 'px',
                          top: contextMenuTop + 'px',
                        }"
                      >
                        <div>
                          <div @click.stop="copyText">{{ $t("AI.tip2") }}</div>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="input-wrap input-wrap-deep">
                <el-input
                  v-model="gptInputValue"
                  :disabled="gptInputDisabled"
                  size="medium"
                  :placeholder="$t('AI.tip4')"
                  @keyup.enter.native="tab3SendMessage"
                >
                  <template slot="suffix">
                    <i
                      v-show="!gptInputDisabled"
                      class="iconfont icon-fasong"
                      style="color: rgba(42, 130, 228, 1); font-size: 20px"
                      @click="tab3SendMessage"
                    >
                    </i>
                  </template>
                </el-input>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </transition>

    <el-dialog
      :visible.sync="createProgramVisible"
      width="25%"
      center
      :title="$t('AI.tip3')"
      :append-to-body="true"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div>
        <el-form ref="newFileForm">
          <el-form-item>
            <el-input
              v-model="form_name"
              autocomplete="off"
              :placeholder="$t('homeTopBar.newFileForm.name')"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="fileTypeId"
              :placeholder="$t('homeTopBar.newFileForm.type')"
              filterable
              :popper-append-to-body="true"
              style="width: 100%"
            >
              <el-option
                v-for="(option, index) in form_options"
                :key="index"
                :label="option.name"
                :value="option.fileTypeId"
                >{{ option.name }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="createProgram_cancel"
          >{{ $t("btn.cancelBtn") }}
        </el-button>
        <el-button
          type="primary"
          size="small"
          class="button-confirm"
          @click="createProgram_comfirm"
          >{{ $t("btn.newBtn") }}
        </el-button>
      </div>
    </el-dialog>
    <textarea
      ref="text_area"
      style="width: 0; height: 0; opacity: 0"
    ></textarea>
    <el-img-viewer
      v-if="isViewerVisible"
      class="viewer"
      :url-list="nowImgsrc"
      :on-close="closeImgViewer"
    ></el-img-viewer>
  </div>
</template>

<script>
import innerOptions from "./components/innerOptions.vue";
import { ulid } from "ulid";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { aiRobotCreat, aiRobotPPayFeatureStatus } from "@/network/ai/index.js";
import { mapGetters } from "vuex";
import { get_component_license } from "@/network/license/index.js";
import MarkdownIt from "markdown-it";
export default {
  components: {
    innerOptions,
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },

  data() {
    return {
      isViewerVisible: false,
      nowImgsrc: [],
      showDialog: false,
      activeName: "four",
      createProgramVisible: false,
      creatOptionList: [
        {
          label: this.$t("AI.tip8"),
          value: "DEMAND_DOC_OUTLINE_CREATE",
        },
        {
          label: this.$t("AI.tip9"),
          value: "TESTCASE_OUTLINE_CREATE",
        },
        {
          label: this.$t("AI.tip10"),
          value: "GIVEN_CODE_CREATE",
        },
      ],
      perfectOptionList: [
        {
          label: this.$t("AI.tip11"),
          first: true,
          value: "GIVEN_DEMAND_DOC_OPTIMIZE",
        },
        {
          label: this.$t("AI.tip12"),
          first: true,
          value: "GIVEN_TEST_CASE_OPTIMIZE",
        },
        {
          label: this.$t("AI.tip13"),
          first: true,
          value: "GIVEN_CODE_OPTIMIZE",
        },
      ],
      // 固定话术维护
      fontResMap: {
        DEMAND_DOC_OUTLINE_CREATE: this.$t("AI.tip22"),
        DEMAND_DOC_OUTLINE_OPTIMIZE: this.$t("AI.tip23"),
        TESTCASE_OUTLINE_CREATE: this.$t("AI.tip24"),
        TESTCASE_OUTLINE_OPTIMIZE: this.$t("AI.tip25"),
        GIVEN_DEMAND_DOC_OPTIMIZE: this.$t("AI.tip26"),
        GIVEN_TEST_CASE_OPTIMIZE: this.$t("AI.tip27"),
        // 完善代码
        GIVEN_CODE_OPTIMIZE: this.$t("AI.tip28"),
        GIVEN_CODE_OPTIMIZE_NEXT: this.$t("AI.tip29"),
        DEMAND_DOC_OUTLINE_OPTIMIZE_NEXT: this.$t("AI.tip23"),
        TESTCASE_OUTLINE_OPTIMIZE_NEXT: this.$t("AI.tip25"),
        CODE_OUTLINE_OPTIMIZE_NEXT: this.$t("AI.tip29"),
        // 生成代码
        GIVEN_CODE_CREATE: this.$t("AI.tip30"),
        GENERATE_CODE_OPTIMIZE: this.$t("AI.tip29"),
      },
      // tab1聊天内容
      creatChatList: [],
      // tab2聊天内容
      perfectChatList: [],
      // tab3聊天内容
      gptChatList: [
        {
          type: "2",
          content: this.$t("AI.tip36"),
          originContent: this.$t("AI.tip36"),
          isRobot: true,
          contextMenuVisible: false,
        },
      ],
      // tab4聊天内容
      ragList: [
        {
          type: "2",
          content: this.$t("AI.tip37"),
          originContent: this.$t("AI.tip37"),
          isRobot: true,
          contextMenuVisible: false,
        },
      ],
      creatInputValue: "",
      perfectInputValue: "",
      gptInputValue: "",
      ragInputValue: "",
      // eventSoruce 对象
      source: null,
      // 记录用户正在进行的操作
      tab1LastOpt: "",
      tab2LastOpt: "",
      // 最后一次机器人返回的整体内容
      tab1LastContent: "",
      // 最后一次机器人返回的原始内容
      tab1LastContentOrigin: "",
      inputDisabled: true,
      gptInputDisabled: false,
      ragInputDisabled: false,
      eventSource: null,
      // 创建文件相关
      form_name: "",
      fileTypeId: "",
      test_plan: "",
      test_case: "",
      // 右键菜单是否显示
      contextMenuVisible: false,
      contextMenuTop: 0,
      contextMenuLeft: 0,
      rightClickObj: {},
      rightClickDom: null,
      // 判断机器人是否正在输入
      robotTyping: false,
      operateType: "", //记录创建文件的时候是需求还是测试计划
      tab1Ulid: "",
      tab2Ulid: "",
      tab3Ulid: ulid(),
      tab4Ulid: ulid(),
      // 用户完善输入的文档或者测试用例
      tab2LastContent: "",
      // 用户完善输入的文档或者测试用例原始内容
      tab2LastContentOrigin: "",
      // 判断是否已经输入了语言类型和代码需求
      hasCodeDemand: "",
      hasCodeLanguage: "",
      dialog_style: [434, 452], // open-ai-dialog的宽高
      conversationId: "",
      licenseList: [],
    };
  },
  computed: {
    ...mapGetters(["token", "fileType", "testPlan"]),
    // 测试用例和需求话术有区别
    optimizeOptionList() {
      const arr = [
        {
          label: this.$t("AI.tip31"),
          value: "DEMAND_DOC_OUTLINE_OPTIMIZE",
        },
        {
          label: this.$t("AI.tip32"),
          value: "CREATE_FILE",
        },
      ];
      if (
        this.tab1LastOpt === "DEMAND_DOC_OUTLINE_CREATE" &&
        this.activeName == "first"
      ) {
        arr[0].label = this.$t("AI.tip31");
        arr[0].value = "DEMAND_DOC_OUTLINE_OPTIMIZE";
        arr[1].label = this.$t("AI.tip32");
      } else if (
        this.tab1LastOpt === "TESTCASE_OUTLINE_CREATE" &&
        this.activeName == "first"
      ) {
        arr[0].label = this.$t("AI.tip33");
        arr[0].value = "TESTCASE_OUTLINE_OPTIMIZE";
        arr[1].label = this.$t("AI.tip34");
      } else if (
        this.tab1LastOpt === "GIVEN_CODE_CREATE" &&
        this.activeName == "first"
      ) {
        arr[0].label = this.$t("AI.tip35");
        arr[0].value = "GENERATE_CODE_OPTIMIZE";
        arr.pop();
      } else if (
        this.tab1LastOpt === "GENERATE_CODE_OPTIMIZE" &&
        this.activeName == "first"
      ) {
        arr[0].label = this.$t("AI.tip35");
        arr[0].value = "GENERATE_CODE_OPTIMIZE";
        arr.pop();
      } else if (
        this.tab2LastOpt === "GIVEN_DEMAND_DOC_OPTIMIZE" &&
        this.activeName == "second"
      ) {
        arr[0].label = this.$t("AI.tip31");
        arr[0].value = "DEMAND_DOC_OUTLINE_OPTIMIZE";
        arr[1].label = this.$t("AI.tip32");
      } else if (
        this.tab2LastOpt === "GIVEN_TEST_CASE_OPTIMIZE" &&
        this.activeName == "second"
      ) {
        arr[0].label = this.$t("AI.tip33");
        arr[0].value = "TESTCASE_OUTLINE_OPTIMIZE";
        arr[1].label = this.$t("AI.tip34");
      } else if (
        this.tab2LastOpt === "GIVEN_CODE_OPTIMIZE" &&
        this.activeName == "second"
      ) {
        arr[0].label = this.$t("AI.tip35");
        arr[0].value = "GENERATE_CODE_OPTIMIZE";
        arr.pop();
      } else if (
        this.tab2LastOpt === "GENERATE_CODE_OPTIMIZE" &&
        this.activeName == "second"
      ) {
        arr[0].label = this.$t("AI.tip35");
        arr[0].value = "GENERATE_CODE_OPTIMIZE";
        arr.pop();
      }
      return arr;
    },
    form_options() {
      return Object.values(this.fileType);
    },
    languageType() {
      let type = "";
      const lang = localStorage.getItem("lang");
      if (lang === "en") {
        type = "en-US";
      } else if (lang === "zh") {
        type = "zh-CN";
      }
      return type;
    },
  },
  watch: {
    contextMenuVisible(val) {
      if (val) {
        document
          .getElementById("tab1-inner-text")
          .addEventListener("click", this.closeMenu);
        document
          .getElementById("tab2-inner-text")
          .addEventListener("click", this.closeMenu);
        document
          .getElementById("tab3-inner-text")
          .addEventListener("click", this.closeMenu);
      } else {
        document
          .getElementById("tab1-inner-text")
          .removeEventListener("click", this.closeMenu);
        document
          .getElementById("tab2-inner-text")
          .removeEventListener("click", this.closeMenu);
        document
          .getElementById("tab3-inner-text")
          .removeEventListener("click", this.closeMenu);
      }
    },
  },
  mounted() {
    this.verifyEventSoure();
    this.get_component_license();
  },
  methods: {
    closeImgViewer() {
      this.isViewerVisible = false;
    },
    get_component_license() {
      get_component_license().then((res) => {
        this.licenseList = [];
        for (let item of res) {
          if (item.effective) {
            this.licenseList.push(item.feature);
          }
        }
      });
    },
    enddrag(a, b) {
      document.getElementById("open-ai-wrap").style.top = a.pageY - 25 + "px";
      document.getElementById("open-ai-wrap").style.left = a.pageX - 25 + "px";
      document.getElementById("open-ai-wrap").style.bottom =
        a.pageY + 25 + "px";
      document.getElementById("open-ai-wrap").style.right = a.pageX + 25 + "px";
    },
    //打开右键菜单
    openMenu(e, item) {
      this.$set(this.rightClickObj, "contextMenuVisible", false);
      if (!this.robotTyping && item.isRobot) {
        this.contextMenuLeft = e.offsetX;
        this.contextMenuTop = e.layerY;
        this.rightClickObj = item;
        this.rightClickDom = e.target;
        item.contextMenuVisible = true;
        this.contextMenuVisible = true;
      }
    },
    // 关闭右键菜单
    closeMenu() {
      this.$set(this.rightClickObj, "contextMenuVisible", false);
      this.rightClickObj = {};
      this.rightClickDom = null;
      this.contextMenuVisible = false;
    },
    // 复制内容
    copyText() {
      let text2 = this.$refs["text_area"];
      text2.innerText = this.rightClickDom.innerText;
      text2.select();
      document.execCommand("copy");
      this.$message({
        type: "success",
        message: this.$t("AI.tip14"),
      });
      this.closeMenu();
    },
    // 右键创建文件
    creatFile(item) {
      aiRobotPPayFeatureStatus().then((res) => {
        if (res) {
          this.operateType = item.opt;
          this.createProgramVisible = true;
        } else {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=ai" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        }
      });
      this.contextMenuVisible = false;
    },
    // 新建文件弹窗相关
    createProgram_comfirm() {
      if (!this.form_name) {
        this.$message({
          type: "warning",
          message: this.$t("AI.tip15"),
        });
      } else if (!this.fileTypeId) {
        this.$message({
          type: "warning",
          message: this.$t("AI.tip16"),
        });
      } else {
        const map = {
          DEMAND_DOC_OUTLINE_CREATE: "CREATE_DEMAND_DOC_FILE",
          DEMAND_DOC_OUTLINE_OPTIMIZE: "CREATE_DEMAND_DOC_FILE",
          GIVEN_DEMAND_DOC_OPTIMIZE: "CREATE_DEMAND_DOC_FILE",
          TESTCASE_OUTLINE_CREATE: "CREATE_TESTCASE_FILE",
          TESTCASE_OUTLINE_OPTIMIZE: "CREATE_TESTCASE_FILE",
          GIVEN_TEST_CASE_OPTIMIZE: "CREATE_TESTCASE_FILE",
        };
        let operateType = map[this.operateType];
        this.createProgramVisible = false;
        const params = {
          projectId: this.get_pid(),
          data: {
            fileTypeId: this.fileTypeId,
            fileName: this.form_name,
            lastRobotMessage: this.rightClickObj.originContent
              ? this.rightClickObj.originContent
              : this.activeName === "first"
              ? this.tab1LastContentOrigin
              : this.tab2LastContentOrigin,
            operateType: operateType,
          },
        };
        aiRobotCreat(params)
          .then((res) => {
            this.$message({
              type: "success",
              message: this.$t("homeTopBar.asyncTask.message"),
            });
          })
          .catch((err) => {
            this.$message({
              type: "warning",
              message: this.$t("AI.tip18"),
            });
          });
      }
    },
    createProgram_cancel() {
      //新建文件 取消
      this.createProgramVisible = false;
      this.form_name = "";
      this.fileTypeId = "";
      this.rightClickObj = {};
      this.rightClickDom = null;
      this.operateType = "";
    },
    // 选择选项事件
    optionSelect(item, index) {
      if (item.value !== "CREATE_FILE") {
        this.activeName === "first"
          ? (this.tab1LastOpt = item.value)
          : (this.tab2LastOpt = item.value);
        if (this.activeName === "first") {
          this.creatChatList.push({
            type: "1",
            content: item.label,
          });
          this.creatChatList.push({
            type: "2",
            content: this.fontResMap[item.value],
          });
        } else {
          this.perfectChatList.push({
            type: "1",
            content: item.label,
          });
          if (item.first) {
            this.perfectChatList.push({
              type: "2",
              content: this.fontResMap[item.value],
            });
          } else {
            this.perfectChatList.push({
              type: "2",
              last: true,
              content:
                item.value === "GENERATE_CODE_OPTIMIZE"
                  ? this.fontResMap["GENERATE_CODE_OPTIMIZE"]
                  : this.fontResMap[item.value + "_NEXT"],
            });
          }
        }

        this.inputDisabled = false;
        // 点击选项滚动条滚动的到最下面
        this.$nextTick(() => {
          const div =
            this.activeName === "first"
              ? document.getElementById("tab1-inner-text")
              : document.getElementById("tab2-inner-text");
          div.scrollTop = div.scrollHeight;
        });
        if (this.activeName === "first") {
          if (
            (item.value === "DEMAND_DOC_OUTLINE_OPTIMIZE" ||
              item.value === "TESTCASE_OUTLINE_OPTIMIZE" ||
              item.value === "GENERATE_CODE_OPTIMIZE") &&
            index
          ) {
            this.creatChatList.splice(index, 1);
          } else {
            this.tab1Ulid = ulid();
            for (let i = this.creatChatList.length - 1; i >= 0; i--) {
              if (this.creatChatList[i].type == "3") {
                this.creatChatList.splice(i, 1);
              }
            }
          }
        } else {
          if (index) {
            this.perfectChatList.splice(index, 1);
          } else {
            this.tab2Ulid = ulid();
            for (let i = this.perfectChatList.length - 1; i >= 0; i--) {
              if (this.perfectChatList[i].type == "3") {
                this.perfectChatList.splice(i, 1);
              }
            }
          }
        }
      } else {
        aiRobotPPayFeatureStatus().then((res) => {
          if (res) {
            this.activeName === "first"
              ? (this.operateType = this.tab1LastOpt)
              : (this.operateType = this.tab2LastOpt);
            this.createProgramVisible = true;
          } else {
            this.$message({
              duration: 0,
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: `<span>${this.$t("certificate.Please")}<a href="${
                window.location.origin
              }/profile/certificate_management?target=ai" target="_blank" style="color: blue">${this.$t(
                "certificate.CertificateManagement"
              )}</a>${this.$t("certificate.InstallComponents")}</span>`,
            });
          }
        });
      }
    },
    verifyEventSoure() {
      if (typeof EventSource === "undefined") {
        this.$message({
          type: "warning",
          message: this.$t("AI.tip19"),
        });
      }
    },
    //  生成代码
    generateCode(self) {
      this.inputDisabled = true;
      const obj = {
        type: "2",
        content: "",
        isRobot: true,
        contextMenuVisible: false,
        opt: this.tab1LastOpt, //记录一下这个消息是需求还是测试用例
      };
      this.creatChatList.push(obj);
      const div = document.getElementById("tab1-inner-text");
      let flag = false;
      self.robotTyping = true;
      self.eventSource = fetchEventSource(
        `${self.wsurl}${
          self.wsurl ? "" : "/prod-api"
        }/feature/file-manage-service-featuredeepseek/${self.get_pid()}/aiRobot/generateCode`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: self.token,
            "Accept-Language": self.languageType,
          },
          body: JSON.stringify({
            sessionId: self.tab1Ulid,
            content: self.hasCodeDemand,
            lang: self.hasCodeLanguage,
          }),
          openWhenHidden: true,
          onmessage(event) {
            const data = JSON.parse(event.data);
            if (data.value) {
              obj.originContent += data.value;
              obj.content += data.value
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replaceAll("\\n", "</br>")
                .replaceAll(" ", "&ensp;");
            }
            if (!flag) {
              flag = true;
              setTimeout(() => {
                div.scrollTop = div.scrollHeight;
                flag = false;
              }, 500);
            }
          },
          onerror(err) {
            self.robotTyping = false;
            throw err;
          },
          onclose() {
            self.robotTyping = false;
            self.creatInputValue = "";
            self.creatChatList.push({
              type: 3,
              content: "",
            });
            self.tab1LastContent = obj.content;
            self.tab1LastContentOrigin = obj.originContent;
            // 回答完毕后清空暂存内容
            self.hasCodeDemand = "";
            self.hasCodeLanguage = "";
          },
        }
      );
    },
    // 发送消息
    tab1SendMessage() {
      aiRobotPPayFeatureStatus().then((res) => {
        if (res) {
          const self = this;
          if (this.creatInputValue) {
            // 对code的特殊处理
            if (this.tab1LastOpt === "GIVEN_CODE_CREATE") {
              if (!this.hasCodeDemand && !this.hasCodeLanguage) {
                this.creatChatList.push({
                  type: "1",
                  content: this.creatInputValue,
                  codeDemand: true,
                });
                this.creatChatList.push({
                  type: "2",
                  content: this.$t("AI.tip20"),
                });
                this.hasCodeDemand = this.creatInputValue;
              } else if (this.hasCodeDemand && !this.hasCodeLanguage) {
                this.creatChatList.push({
                  type: "1",
                  content: this.creatInputValue,
                  codeLanguage: true,
                });
                this.hasCodeLanguage = this.creatInputValue;
                this.generateCode(self);
              }
              this.creatInputValue = "";
            } else {
              this.inputDisabled = true;
              this.creatChatList.push({
                type: "1",
                content: this.creatInputValue,
              });
              const obj = {
                type: "2",
                content: "",
                originContent: "",
                isRobot: true,
                contextMenuVisible: false,
                opt: this.tab1LastOpt, //记录一下这个消息是需求还是测试用例
              };
              this.creatChatList.push(obj);
              if (
                this.tab1LastOpt === "DEMAND_DOC_OUTLINE_CREATE" ||
                this.tab1LastOpt === "TESTCASE_OUTLINE_CREATE"
              ) {
                this.tab1CreatEventSource(self, obj);
              } else {
                this.tab1OptimizeEventSource(self, obj);
              }
            }
          } else {
            self.$message({
              type: "warning",
              message: this.$t("AI.tip21"),
            });
          }
        } else {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=ai" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        }
      });
    },
    tab2SendMessage() {
      aiRobotPPayFeatureStatus().then((res) => {
        if (res) {
          const self = this;
          if (this.perfectInputValue) {
            if (!this.perfectChatList[this.perfectChatList.length - 1].last) {
              this.perfectChatList.push({
                type: "1",
                content: this.perfectInputValue,
              });
              this.tab2LastContent = this.perfectInputValue;
              const map = {
                GIVEN_DEMAND_DOC_OPTIMIZE: "DEMAND_DOC_OUTLINE_OPTIMIZE_NEXT",
                GIVEN_TEST_CASE_OPTIMIZE: "TESTCASE_OUTLINE_OPTIMIZE_NEXT",
                GIVEN_CODE_OPTIMIZE: "GIVEN_CODE_OPTIMIZE_NEXT",
                GENERATE_CODE_OPTIMIZE: "GENERATE_CODE_OPTIMIZE",
              };
              this.perfectChatList.push({
                type: "2",
                last: true, //标识下一个就该ai输出了
                content: this.fontResMap[map[this.tab2LastOpt]],
              });
              this.perfectInputValue = "";
            } else {
              this.inputDisabled = true;
              this.perfectChatList.push({
                type: "1",
                content: this.perfectInputValue,
              });
              const obj = {
                type: "2",
                content: "",
                originContent: "",
                isRobot: true,
                contextMenuVisible: false,
                opt: this.tab2LastOpt, //记录一下这个消息是需求还是测试用例
              };
              this.perfectChatList.push(obj);
              if (
                this.tab2LastOpt === "DEMAND_DOC_OUTLINE_OPTIMIZE" ||
                this.tab2LastOpt === "TESTCASE_OUTLINE_OPTIMIZE" ||
                this.tab2LastOpt === "GENERATE_CODE_OPTIMIZE"
              ) {
                this.tab2OptimizeEventSource(self, obj);
              } else {
                this.tab2CreatEventSource(self, obj);
              }
            }
          } else {
            self.$message({
              type: "warning",
              message: this.$t("AI.tip21"),
            });
          }
        } else {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=ai" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        }
      });
    },
    tab3SendMessage() {
      if (this.licenseList.indexOf("SAFETY_GPT") !== -1) {
        const self = this;
        // 添加问答
        this.gptInputDisabled = true;
        this.gptChatList.push({
          type: "1",
          content: this.gptInputValue,
        });
        const obj = {
          type: "2",
          content: "",
          originContent: "",
          isRobot: true,
          contextMenuVisible: false,
        };
        this.gptChatList.push(obj);
        const div = document.getElementById("tab3-inner-text");
        let flag = false;
        self.robotTyping = true;
        self.eventSource = fetchEventSource(
          `${self.wsurl}${
            self.wsurl ? "" : "/prod-api"
          }/file-manage-service/${self.get_pid()}/safetyGpt/conversation`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: self.token,
              "Accept-Language": self.languageType,
            },
            body: JSON.stringify({
              sessionId: self.tab3Ulid,
              content: self.gptInputValue,
              conversationId: self.conversationId,
            }),
            openWhenHidden: true,
            onmessage(event) {
              const data = JSON.parse(event.data);
              if (data.conversationId) {
                self.conversationId = data.conversationId;
              }

              if (data.value) {
                obj.originContent += data.value;
                obj.content += data.value;
              }
              if (!flag) {
                flag = true;
                setTimeout(() => {
                  div.scrollTop = div.scrollHeight;
                  flag = false;
                }, 500);
              }
            },
            onerror(err) {
              self.robotTyping = false;
              throw err;
            },
            onclose() {
              self.robotTyping = false;
              self.gptInputDisabled = false;
              self.gptInputValue = "";
              const md = new MarkdownIt();
              obj.content = md.render(obj.content);
            },
          }
        );
      } else {
        this.$message({
          duration: 0,
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: `<span>${this.$t("certificate.Please")}<a href="${
            window.location.origin
          }/profile/certificate_management?target=safety" target="_blank" style="color: blue">${this.$t(
            "certificate.CertificateManagement"
          )}</a>${this.$t("certificate.InstallComponents")}</span>`,
        });
      }
    },
    tab4SendMessage() {
      aiRobotPPayFeatureStatus().then((res) => {
        if (res) {
          const self = this;
          // 添加问答
          this.ragInputDisabled = true;
          this.ragList.push({
            type: "1",
            content: this.ragInputValue,
          });
          const obj = {
            type: "2",
            content: "",
            originContent: "",
            isRobot: true,
            contextMenuVisible: false,
          };
          this.ragList.push(obj);
          const div = document.getElementById("tab4-inner-text");
          let flag = false;
          self.robotTyping = true;
          self.eventSource = fetchEventSource(
            `${self.wsurl}${
              self.wsurl ? "" : "/prod-api"
            }/file-manage-service/msWiki/startConversation`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: self.token,
                "Accept-Language": self.languageType,
              },
              body: JSON.stringify({
                message: self.ragInputValue + "," + this.$t("AI.tip38"),
                conversationId: self.tab4Ulid,
              }),
              openWhenHidden: true,
              onmessage(event) {
                if (event.data) {
                  const json = JSON.parse(event.data);
                  obj.originContent += json.value;
                  obj.content += json.value;
                }
                if (!flag) {
                  flag = true;
                  setTimeout(() => {
                    div.scrollTop = div.scrollHeight;
                    flag = false;
                  }, 500);
                }
              },
              onerror(err) {
                self.robotTyping = false;
                throw err;
              },
              onclose() {
                self.robotTyping = false;
                self.ragInputDisabled = false;
                self.ragInputValue = "";
                const md = new MarkdownIt();
                obj.content = md.render(obj.content);
                setTimeout(() => {
                  const imgs = document.querySelectorAll(".chartgtp-box img");
                  const aList = document.querySelectorAll(".chartgtp-box a");
                  imgs.forEach(function (img) {
                    img.addEventListener("click", function ($event) {
                      self.nowImgsrc = [$event.target.currentSrc];
                      self.isViewerVisible = true;
                    });
                  });
                  aList.forEach((a) => {
                    a.target = "_blank";
                  });
                }, 300);
              },
            }
          );
        } else {
          this.$message({
            duration: 0,
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: `<span>${this.$t("certificate.Please")}<a href="${
              window.location.origin
            }/profile/certificate_management?target=ai" target="_blank" style="color: blue">${this.$t(
              "certificate.CertificateManagement"
            )}</a>${this.$t("certificate.InstallComponents")}</span>`,
          });
        }
      });
    },
    //创建的eventSource
    tab1CreatEventSource(self, obj) {
      const div = document.getElementById("tab1-inner-text");
      let flag = false;
      self.robotTyping = true;
      self.eventSource = fetchEventSource(
        `${self.wsurl}${
          self.wsurl ? "" : "/prod-api"
        }/file-manage-service/${self.get_pid()}/aiRobot/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: self.token,
            "Accept-Language": self.languageType,
          },
          body: JSON.stringify({
            sessionId: self.tab1Ulid,
            content: self.creatInputValue,
            operate: self.tab1LastOpt,
          }),
          openWhenHidden: true,
          onmessage(event) {
            const data = JSON.parse(event.data);
            if (data.value) {
              obj.originContent += data.value;
              obj.content += data.value
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replaceAll("\\n", "</br>")
                .replaceAll(" ", "&ensp;");
            }
            if (!flag) {
              flag = true;
              setTimeout(() => {
                div.scrollTop = div.scrollHeight;
                flag = false;
              }, 500);
            }
          },
          onerror(err) {
            self.robotTyping = false;
            throw err;
          },
          onclose() {
            self.robotTyping = false;
            self.creatInputValue = "";
            self.creatChatList.push({
              type: 3,
              content: "",
            });
            self.tab1LastContent = obj.content;
            self.tab1LastContentOrigin = obj.originContent;
          },
        }
      );
    },
    tab2CreatEventSource(self, obj) {
      const div = document.getElementById("tab2-inner-text");
      let flag = false;
      self.robotTyping = true;
      self.eventSource = fetchEventSource(
        `${self.wsurl}${
          self.wsurl ? "" : "/prod-api"
        }/file-manage-service/${self.get_pid()}/aiRobot/givenOptimize`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: self.token,
            "Accept-Language": self.languageType,
          },
          body: JSON.stringify({
            sessionId: self.tab2Ulid,
            content: self.tab2LastContent,
            operateType: self.tab2LastOpt,
            optimizeMessage: self.perfectInputValue,
          }),
          openWhenHidden: true,
          onmessage(event) {
            const data = JSON.parse(event.data);
            if (data.value) {
              obj.originContent += data.value;
              obj.content += data.value
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replaceAll("\\n", "</br>")
                .replaceAll(" ", "&ensp;");
            }
            if (!flag) {
              flag = true;
              setTimeout(() => {
                div.scrollTop = div.scrollHeight;
                flag = false;
              }, 500);
            }
          },
          onerror(err) {
            self.robotTyping = false;
            throw err;
          },
          onclose() {
            self.robotTyping = false;
            self.perfectInputValue = "";
            self.perfectChatList.push({
              type: 3,
              content: "",
            });
            self.tab2LastContent = obj.content;
            self.tab2LastContentOrigin = obj.originContent;
          },
        }
      );
    },
    //完善的eventSource
    tab1OptimizeEventSource(self, obj) {
      const div = document.getElementById("tab1-inner-text");
      let flag = false;
      self.robotTyping = true;
      self.eventSource = fetchEventSource(
        `${self.wsurl}${
          self.wsurl ? "" : "/prod-api"
        }/file-manage-service/${self.get_pid()}/aiRobot/optimize`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: self.token,
            "Accept-Language": self.languageType,
          },
          body: JSON.stringify({
            sessionId: self.tab1Ulid,
            content: self.creatInputValue,
            operate: self.tab1LastOpt,
            lastRobotMessage: self.tab1LastContentOrigin,
          }),
          openWhenHidden: true,
          onmessage(event) {
            const data = JSON.parse(event.data);
            if (data.value) {
              obj.originContent += data.value;
              obj.content += data.value
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replaceAll("\\n", "</br>")
                .replaceAll(" ", "&ensp;");
            }
            if (!flag) {
              flag = true;
              setTimeout(() => {
                div.scrollTop = div.scrollHeight;
                flag = false;
              }, 500);
            }
          },
          onerror(err) {
            self.robotTyping = false;
            throw err;
          },
          onclose() {
            self.robotTyping = false;
            self.creatInputValue = "";
            self.creatChatList.push({
              type: 3,
              content: "",
            });
            self.tab1LastContent = obj.content;
            self.tab1LastContentOrigin = obj.originContent;
          },
        }
      );
    },
    tab2OptimizeEventSource(self, obj) {
      const div = document.getElementById("tab2-inner-text");
      let flag = false;
      self.robotTyping = true;
      self.eventSource = fetchEventSource(
        `${self.wsurl}${
          self.wsurl ? "" : "/prod-api"
        }/file-manage-service/${self.get_pid()}/aiRobot/optimize`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: self.token,
            "Accept-Language": self.languageType,
          },
          body: JSON.stringify({
            sessionId: self.tab2Ulid,
            content: self.perfectInputValue,
            operate: self.tab2LastOpt,
            lastRobotMessage: self.tab2LastContentOrigin,
          }),
          openWhenHidden: true,
          onmessage(event) {
            const data = JSON.parse(event.data);
            if (data.value) {
              obj.originContent += data.value;
              obj.content += data.value
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replaceAll("\\n", "</br>")
                .replaceAll(" ", "&ensp;");
            }
            if (!flag) {
              flag = true;
              setTimeout(() => {
                div.scrollTop = div.scrollHeight;
                flag = false;
              }, 500);
            }
          },
          onerror(err) {
            self.robotTyping = false;
            throw err;
          },
          onclose() {
            self.robotTyping = false;
            self.perfectInputValue = "";
            self.perfectChatList.push({
              type: 3,
              content: "",
            });
            self.tab2LastContent = obj.content;
            self.tab2LastContentOrigin = obj.originContent;
          },
        }
      );
    },
    showDialogFn() {
      this.showDialog = !this.showDialog;

      // 确定浮窗出现的位置
      let btn = document
        .getElementById("open-ai-button")
        .getBoundingClientRect();

      // 如果btn位置到最左边的距离小于dia的宽度
      if (btn.left < 560 / (50 / btn.width)) {
        document.getElementById("open-ai-dialog").style.left = btn.width + "px";
      } else {
        document.getElementById("open-ai-dialog").style.left = "-560px";
      }

      let dia_h = 600 / (50 / btn.width);

      if (btn.top < dia_h) {
        if (window.innerHeight - btn.bottom < dia_h) {
          document.getElementById("open-ai-dialog").style.bottom =
            -(dia_h - btn.top) + "px";
        } else {
          document.getElementById("open-ai-dialog").style.bottom = "auto";
        }
      } else {
        document.getElementById("open-ai-dialog").style.bottom = "0";
      }
    },
  },
};
</script>
<style lang="scss">
.chartgtp-box {
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
</style>
<style scoped lang="scss">
.dialog-footer {
  display: flex;
  justify-content: right;
}
.context-menu {
  position: absolute;
  width: 130px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  div {
    div {
      height: 40px;
      line-height: 40px;
      color: rgba(48, 100, 143, 1);
      font-size: 12px;
      text-align: center;
      cursor: default;
      &:hover {
        background: rgba(229, 229, 229, 0.61);
        cursor: pointer;
      }
    }
  }
}
.open-ai-wrap {
  position: absolute;
  right: 25px;
  bottom: 65px;
  z-index: 1999;
  cursor: pointer;
  .open-ai-button {
    display: flex;
    background-color: rgba(24, 79, 169, 1);
    align-items: center;
    color: white;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: none;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    img {
      width: 100%;
      height: 100%;
    }
  }
  .open-ai-dialog {
    .close-open-ai-dialog {
      cursor: pointer;
      text-align: right;
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 16px;
    }
    width: 560px;
    background-color: rgba(240, 242, 245, 1);
    position: absolute;
    bottom: 0;
    left: -570px;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    .tab-inner {
      width: 100%;
      height: 480px;
      padding-top: 18px;
      padding-bottom: 60px;
      .tab-inner-text {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        padding-right: 6px;
        position: relative;
      }
      .input-wrap {
        width: 100%;
        position: absolute;
        bottom: 0px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        ::v-deep .el-input__suffix {
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .chat-box {
        margin: 12px 0;
        & > span {
          font-size: 14px;
          display: inline-block;
          max-width: 325px;
          border-radius: 5px;
          padding: 8px 16px;
          word-break: break-all;
          width: fit-content;
        }
      }
      .human-chart {
        text-align: right;
        & > span {
          background-color: rgba(140, 191, 250, 1);
          text-align: left;
        }
      }
      .robot-chart {
        text-align: left;
        display: flex;
        flex-direction: column;
        position: relative;
        & > span {
          background-color: rgba(255, 255, 255, 1);
        }
      }
      .chartgtp-box {
        word-wrap: break-word !important; /* 对长单词或URL进行换行 */
        word-break: break-word !important; /* 对长单词进行换行，兼容性更好 */
        /* 或者使用 word-break: break-word; */
        &:hover {
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
.dot-elastic {
  display: inline-block;
  position: absolute;
  bottom: -20px;
  left: 40px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2a82e4;
  color: #2a82e4;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2a82e4;
  color: #2a82e4;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2a82e4;
  color: #2a82e4;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
